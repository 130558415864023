import { createContext, useState } from "react";
import axios from "axios";

const defaultProvider = {
  loading: false,
  setLoading: () => Boolean,
  success: true,
  error: "",
  subscriptionId: "",
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = (children) => {
  const [loading, setLoading] = useState(defaultProvider.loading);
  const [success, setSuccess] = useState(defaultProvider.success);
  const [error, setError] = useState(defaultProvider.error);
  const [subscriptionId, setSubscriptionId] = useState(
    defaultProvider.subscriptionId
  );

  const handleLogin = (params) => {
    setLoading(true);
    axios
      .get(
        `https://vas-api-test.secondprofit.com/subscribe/?${params.msisdn}&lang=${params.lang}`
      )
      .then(function (response) {
        if (response.data.status === "success") {
          setLoading(false);
          setSubscriptionId(response.data.subscription_id);
          setSuccess(false);
          setError("");
        }
        if (response.data.status === "failed") {
          setLoading(false);
          setError(response.data.user_friendly_message);
        }
      })
      .catch(function (error) {
        console.log(error.data);
      });
  };

  const handelVerify = (params) => {
    setLoading(true);
    axios
      .get(
        `https://vas-api-test.secondprofit.com/verify/?subscription_id=${subscriptionId}&pin=${params.code}`
      )
      .then(function (response) {
        if (response.data.status === "success") {
          setLoading(false);
          window.location.assign(response.data.redirect_url);
          setError("");
        }
        if (response.data.status === "failed") {
          setLoading(false);
          setError(response.data.user_friendly_message);
        }
      })
      .catch(function (error) {
        console.log(error.data);
      });
  };

  const handelUnSubscribe = (params) => {
    const token = sessionStorage.getItem("token");
    var code = sessionStorage.getItem("code");
    var codeValue = code ? `&code=${code}` : "";
    const tokenValue = token ? token : "";
    setLoading(true);
    axios
      .get(
        `https://vas-api.secondprofit.com/cancel/?token=${tokenValue}${codeValue}${params.msisdn}`
      )
      .then(function (response) {
        if (response.data.status === "success") {
          setLoading(false);
          setSuccess(true);
        }
        if (response.data.status === "failed") {
          setLoading(false);
          setError(response.data.user_friendly_message);
        }
      })
      .catch(function (error) {
        console.log(error.data);
      });
  };
  const values = {
    loading,
    login: handleLogin,
    success,
    error,
    subscriptionId,
    handelVerify,
    handelUnSubscribe
  };

  return (
    <AuthContext.Provider value={values}>
      {children.children}
    </AuthContext.Provider>
  );
};


export { AuthContext, AuthProvider };
