import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import Spinner from "react-bootstrap/Spinner";
import "swiper/css";
import React, { memo } from 'react';
// import ScrollToTop from "./component/layout/scrolltop";
// import AboutPage from "./pages/about";
// import AchievementPage from "./pages/achievement";
// import BlogPage from "./pages/blog";
// import BlogDetails from "./pages/blog-single";
// import BlogPageTwo from "./pages/blogtwo";
// import ContactUs from "./pages/contact";
// import Profile from "./pages/profile";
// import GalleryPage from "./pages/gallery";
// import GameListSection from "./pages/gamelist";
// import GameListTwoSection from "./pages/gamelisttwo";
// import HomePage from "./pages/home";
// import HomeTwo from "./pages/hometwo";
// import LogIn from "./pages/login";
// import PartnerPage from "./pages/partner";
// import ShopPage from "./pages/shop";
// import ShopCart from "./pages/shopcart";
// import ShopDetails from "./pages/shopdetails";
// import SignUp from "./pages/signup";
// import TeamPage from "./pages/team";
// import TeamSinglePage from "./pages/team-single";
// import ErrorPage from "./pages/errorpage";
// import Privacy from "./pages/privacy";
// import Terms from "./pages/terms";
import "./utils/language/i18n";
import { useTranslation } from "react-i18next";
const HomePage = lazy(() => import("./pages/home"));
const GameListSection = lazy(() => import("./pages/gamelist"));
const ErrorPage = lazy(() => import("./pages/errorpage"));
const ContactUs = lazy(() => import("./pages/contact"));
const Profile = lazy(() => import("./pages/profile"));
const LogIn = lazy(() => import("./pages/login"));
const Privacy = lazy(() => import("./pages/privacy"));
const Terms = lazy(() => import("./pages/terms"));
const ScrollToTop = lazy(() => import("./component/layout/scrolltop"));

function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="spinner-container">
            <Spinner animation="border" variant="secondary" />
          </div>
        }
      >
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="index-2" element={<HomeTwo />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="gallery" element={<GalleryPage />} /> */}
          <Route path="games" element={<GameListSection />} />
          {/* <Route path="game-list2" element={<GameListTwoSection />} />
        <Route path="partners" element={<PartnerPage />} />
        <Route path="achievements" element={<AchievementPage />} />
        <Route path="team" element={<TeamPage />} />
        <Route path="team-single" element={<TeamSinglePage />} /> */}
          <Route path="*" element={<ErrorPage />} />
          {/* <Route path="shop" element={<ShopPage />} />
        <Route path="shop-single" element={<ShopDetails />} />
        <Route path="cart-page" element={<ShopCart />} />
        <Route path="blog" element={<BlogPage />} />
        <Route path="blog-2" element={<BlogPageTwo />} />
        <Route path="blog-single" element={<BlogDetails />} /> */}
          <Route path="contact" element={<ContactUs />} />
          <Route path="profile" element={<Profile />} />
          <Route path="login" element={<LogIn />} />
          {/* <Route path="signup" element={<SignUp />} /> */}
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default memo(App);
